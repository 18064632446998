<template>
   <!-- create container covering all screen -->
   <div id='mapid' class='mainmap h-screen w-screen'></div>

   <div class="map-overlay top">
      <div class="map-overlay-inner">
            <div id="soiltypes"></div>
            <button @click="openModal">Paramètres</button>
      </div>
   </div>

   <div class="modal" v-if="showModal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <label for="passwordInput">Mot de passe :</label>
      <input type="password" id="passwordInput" v-model="inputPassword">
      <div v-if="showSettings">
         <hr>
         <label for="passwordInput">Uploader le fichier de paramétrage json puis cliquer sur "importer"</label>
         <input type="file" id="selectFiles" value="" />
         <button id="import" @click="importJson">Importer</button>
         <pre class="scroll" id="result" v-html="this.formattedSettings"></pre>
         <button type="button" @click="submitSettings" style="border-color: red;">Initialiser la carte avec ces paramètres</button>
         <hr>
         <br>
         <button type="button" @click="saveMap">Exporter la carte</button>
      </div>
      <button type="button" @click="closeModal">Fermer</button>
    </div>
  </div>

</template>
  
<script>
import mapboxgl from 'mapbox-gl'; // loads mapbox library
import { onMounted } from 'vue';
 
export default {
   name: 'ReliefMap',

   created() {
      onMounted(() => {
         this.initWebsocket();
         this.initMap();
      });
   },

   data() {
      return {
         ws: null,
         map: null, // for now, no map
         hoveredZoneId: null, // no hovered zone
         paintedZoneId: null, // no painted zone
         selectedButton: null, // the button that was previously pressed
         ctrlKeyPressed: false,
         color: '#000000',
         currentSoiltype: null, // 
         modifiedZonesIds: [],
         modifiedZones: [],
         gridClassifications: Array(0), // empty Array that will save the inputs
         password: 'courteille',
         inputPassword: '',
         showModal: false,
         colorScale: [],
         formattedSettings: '',
         tempSettings: null,
         settings: null
         // settings: {
         //    borderName: 'joyeux_contour-dqueev',
         //    borderUrl: 'mapbox://laco504.6isrloib',
         //    gridName: 'joyeux_grid-6hu93p',
         //    gridUrl: 'mapbox://laco504.2zebwjkw',
         //    lon: 5.101379,
         //    lat: 45.963128,
         //    gridLength: 45721,
         //    soiltypes: { // dict of soil types with their names and assigned colors
         //       0: {
         //          name: 'Effacer',
         //          color: '#000000',
         //          display: true
         //       },
         //       1: {
         //          name: '1a Argiles rouges',
         //          color: '#9810ec',
         //          display: true
         //       },
         //       2: {
         //          name: '1b Argiles rouges',
         //          color: '#e40202',
         //          display: true
         //       },
         //       3: {
         //          name: '2 Terres blanches',
         //          color: '#989c14',
         //          display: true
         //       },
         //       4: {
         //          name:'3 Terres blanches',
         //          color: '#639814',
         //          display: true
         //       },
         //       5: {
         //          name: '4 Argiles rouges',
         //          color: '#4f10ec',
         //          display: true
         //       },
         //       6: {
         //          name: '5 Terres blanches',
         //          color: '#e7e784',
         //          display: true
         //       },
         //       7: {
         //          name: '6 Sols sur Eocène',
         //          color: '#0fcdca',
         //          display: true
         //       },
         //       8: {
         //          name: '9 Terres blanches',
         //          color: '#e7bf62',
         //          display: true
         //       },
         //       9: {
         //          name: '10 Terres blanches',
         //          color: '#85f232',
         //          display: true
         //       },
         //       10: {
         //          name: '11 Argiles rouges',
         //          color: '#8c0505',
         //          display: true
         //       },
         //       11: {
         //          name: '12 Argiles rouges',
         //          color: '#ec6a00',
         //          display: true
         //       },
         //       12: {
         //          name: '13 Argiles rouges',
         //          color: '#e86f6f',
         //          display: true
         //       },
         //       13: {
         //          name: '14 Argiles marrons',
         //          color: '#685021',
         //          display: true
         //       }
         //    },
         // },
      };
   },

   computed: {
      showSettings: function () {
         return this.inputPassword == this.password;
      }
   },

   methods: {

      cleanMap() { // removes grid and border
         if (this.map.getLayer('grid')) {
            this.map.removeLayer('grid'); // remove current grid layer
         }
         if (this.map.getSource('gridSource')) {
            this.map.removeSource('gridSource'); // remove matching source
         }
         if (this.map.getLayer('border')) {
            this.map.removeLayer('border'); // remove current border layer
         }
         if (this.map.getSource('borderSource')) {
            this.map.removeSource('borderSource'); // remove matching source
         }
      },

      closeModal() {
         this.showModal = false;
      },

      // This function detects mouse movements and changes opacity of hovered polygons.
      // If the Ctrl Key is pressed while the polygons are being hovered, they are "painted".
      handleMouseMove(e) {
         if (e.features.length > 0) {
            if (this.hoveredZoneId !== null) { // if no polygon is hovered (the mouse is outside the map)
               this.map.setFeatureState(
                  { source: 'gridSource', sourceLayer: this.settings.gridName, id: this.hoveredZoneId },
                  { hover: false } // the polygon which was hovered last (defined by this.hoveredZoneId) is no longer in a "hovered" state
               );
            }
            this.hoveredZoneId = e.features[0].id; // gets id among features of hovered object
            this.map.setFeatureState(
               { source: 'gridSource', sourceLayer: this.settings.gridName, id: this.hoveredZoneId },
               { hover: true } // change feature state of the currently hovered polygon to "hovered"
            );

            if (e.originalEvent.ctrlKey && this.currentSoiltype != null) { // if the Ctrl key is being pressed
               this.ctrlKeyPressed = true;
               var soilType = Number(this.currentSoiltype);
               var paintedZoneIdScreen = e.features[0].id; // this.paintedZoneIdSCREEN is the ID of the polygon on the displayed grid 
               var paintedZoneIdFile = e.features[0].properties.id; // this.paintedZoneIdFILE is the ID of the polygon in the array to be downloaded
               // This two IDs do not match, so we had to create two distinct variables

               if (!this.modifiedZonesIds.includes(paintedZoneIdScreen)) {
                  this.modifiedZonesIds.push(paintedZoneIdScreen);
                  this.modifiedZones.push({
                     'paintedZoneIdScreen': paintedZoneIdScreen,
                     'paintedZoneIdFile': paintedZoneIdFile,
                     'soilType': soilType
                  });
                  this.updateZone(paintedZoneIdScreen, paintedZoneIdFile, soilType);
               }
            } else if (this.ctrlKeyPressed) {
               this.ctrlKeyPressed = false;
               if (!this.ws) {
                  console.log("No WebSocket connection :(");
                  return ;
               }
               console.log("Send zones");
               this.ws.send(JSON.stringify({'zones': this.modifiedZones}));
               this.modifiedZonesIds = [];
               this.modifiedZones = [];
            }
         }
      },

      importJson() {
         const files = document.getElementById('selectFiles').files;
         if (files.length <= 0) {
            return false;
         }
         const fr = new FileReader();
         fr.onload = e => {
            this.tempSettings = JSON.parse(e.target.result);
            this.formattedSettings = JSON.stringify(this.tempSettings, null, 2);
         }
         fr.readAsText(files.item(0));
      },

      initMap() {
         // Specify token to access data stored on mapbox.com
         mapboxgl.accessToken = 'pk.eyJ1IjoibGFjbzUwNCIsImEiOiJjbDZ5dG00N28wc3p0M2lxbXVydTA2MW84In0.pcjx2I2Ves1Eb7YsdDRcBg';
         
         // Define map background
         this.map = new mapboxgl.Map({
            container: 'mapid',
            zoom: 5,
            center: [3.43, 46],
            style: 'mapbox://styles/mapbox/satellite-streets-v12' // load a mapbox built-in style 
         });

         // add navigation control
         const nav = new mapboxgl.NavigationControl({
            showCompass: true,
            showZoom: true
         });
         this.map.addControl(nav, 'top-left'); // add nav object to the top-left
         
         // Load relief data for the 3D view 
         this.map.on('load', () => {
            this.map.addSource('mapbox-dem', {
               type: 'raster-dem',
               url: 'mapbox://mapbox.terrain-rgb',
               tileSize: 512,
               maxzoom: 14
            });

            this.map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 }); // relief data is used to add relief to the map
            
            if (this.settings != null){ // for dev purpose, if settings has already data
               this.initMapWithSettings();
            }
         });
      },

      initMapWithSettings() { // reinitialize grid labels
         this.currentSoiltype = null,
         this.initSoilTypes();
         this.gridClassifications = Array(this.settings.gridLength);
         this.cleanMap();
         this.loadMap();
      },

      initSoilTypes() {
         const soiltypeButtons = document.getElementById('soiltypes'); // link with the html object named 'soiltypes'
         soiltypeButtons.innerHTML = '<label>Choisissez le type de sol :</label>';

         // the color follows a several-step scale
         // the 'feature-state' is determined by the 'soiltype' attribute
         this.colorScale = ['step', ['feature-state', 'soiltype']];

         for (const [key, soiltype] of Object.entries(this.settings.soiltypes)) {
            if (soiltype.display) {
               // create html button
               const el = document.createElement('div'); // create a button for each soiltype
               el.classList.add('btn'); // set class of the new div object
               el.style['background-color'] = soiltype.color; // the color of soiltype is set as background color
               el.innerText = soiltype.name; // the name of soiltype is set as button text
               el.addEventListener('click', (e) => { // if the button is clicked
                  if (this.selectedButton == e.target) {
                     this.currentSoiltype = null;
                     this.selectedButton.style.borderLeft = this.selectedButton.style.borderRight = '0px';
                     this.selectedButton = null;
                  } 
                  else {
                     if (this.selectedButton !== null) {
                        this.selectedButton.style.borderLeft = this.selectedButton.style.borderRight = '0px';
                     }
                     this.selectedButton = e.target
                     this.currentSoiltype = Number(key); // current soiltype is updated
                     this.selectedButton.style.borderLeft = this.selectedButton.style.borderRight = '10px solid black';
                  }
               });
               soiltypeButtons.appendChild(el);

               // add color in scale
               this.colorScale.push(soiltype.color, Number(key)+0.1);
            }
         }
         
         this.colorScale.push('#000000');
      },

      initWebsocket() {
         if (this.ws) {
            this.ws.onerror = this.ws.onopen = this.ws.onclose = null;
            this.ws.close();
         }

         this.ws = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL);
         this.ws.onopen = () => {
            console.log('Connection opened!');
         }
         this.ws.onmessage = (message) => {
            console.log('Received data');
            const data = JSON.parse(message.data);
            if ("zones" in data) {
               this.updateZonesModifiedByOthers(data.zones);
            } else {
               this.settings = data;
               this.initMapWithSettings();
            }
         }
         this.ws.onclose = function() {
            this.ws = null;
         }
      },
 
      loadMap() { // loads new map
         this.map.addSource('gridSource', {
            type: 'vector',
            url: this.settings.gridUrl
         });
 
         this.map.addLayer({
            id: 'grid',
            type: 'fill',
            source: 'gridSource',
            'source-layer': this.settings.gridName,
            layout: {
               visibility: 'visible'
            },
            paint: { // the color of each polygon depends of the soiltype (variable taking discrete values) assigned  to it
               'fill-color': this.colorScale,
               // 'fill-color': [
               //    'step', // the color follows a several-step scale
               //    ['feature-state', 'soiltype'], 
               //    '#000000', 0.5,
               //    '#9810ec', 1.5, // polygons with soiltype < 1.5 (i.e. == 1) are displayed in red
               //    '#e40202', 2.5, // those with soiltype < 2.5 (i.e. == 2) are displayed in green
               //    '#989c14', 3.5,
               //    '#639814', 4.5,
               //    '#4f10ec', 5.5,
               //    '#e7e784', 6.5,
               //    '#0fcdca', 7.5,
               //    '#e7bf62', 8.5,
               //    '#85f232', 9.5,
               //    '#8c0505', 10.5,
               //    '#ec6a00', 11.5,
               //    '#e86f6f', 12.5,
               //    '#685021', 13.5,
               //    '#000000' // else, (i.e. == 10, the initialized value) they are displayed in grey
               // ],
               'fill-opacity': [ // the opacity of each polygon depends on whether it is hovered or not
                  'case', // hovered or not --> 2 cases
                  ['boolean', ['feature-state', 'hover'], false], // by default, hover is false
                  0.6, // if the polygon is hovered (hover==true), the polygon appears darker, less transparent
                  0.4 
               ],
               // 'fill-outline-color': '#3b3b3b'
            }
         });

         // add borderlines of the study zone
         this.map.addSource('borderSource', {
            type: 'vector',
            url: this.settings.borderUrl
         });

         this.map.addLayer({
            id: 'border',
            type: 'line',
            source: 'borderSource',
            'source-layer': this.settings.borderName,
            layout: {
               visibility: 'visible'
            },
            paint: {
               'line-color': '#fd0000'
            }
         });

         this.map.setCenter([this.settings.lon, this.settings.lat]);
         this.map.setZoom(12);
         // var target = {
         //    center: [this.settings.lon, this.settings.lat],
         //    zoom: 12,
         //    bearing: 0,
         //    pitch: 0
         // };
         // this.map.flyTo({
         //    ...target, // Fly to the selected target
         //    duration: 6000, // Animate duration
         //    essential: true // This animation is considered essential with
         //    //respect to prefers-reduced-motion
         // });

         this.map.on('mousemove', 'grid', this.handleMouseMove);
      },

      openModal() {
         this.showModal = true;
      },

      saveMap() { // download a simple txt file with soiltypes (numbers) position in the grid
         const element = document.createElement('a');
         element.href = 'data:attachment/text;charset=utf-8' + encodeURI(this.gridClassifications);
         element.target = '_blank';
         element.download = `gridClass_${this.settings.gridName}.txt`; 
         element.click();
      },

      submitSettings() {
         this.settings = this.tempSettings;
         this.initMapWithSettings();
         this.closeModal();
         if (!this.ws) {
            console.log("No WebSocket connection :(");
            return ;
         } else {
            this.ws.send(JSON.stringify(this.settings));
         }
      },

      updateZone(paintedZoneIdScreen, paintedZoneIdFile, soilType) {
         this.map.setFeatureState(
            { source: 'gridSource', sourceLayer: this.settings.gridName, id: paintedZoneIdScreen },
            { soiltype: soilType }, // the soiltype of the polygon is updated according to the currentSoiltype (defined by pressing a "soiltype" button)
         );
         this.gridClassifications[paintedZoneIdFile] = soilType; // the gridClassifications array is updated
      },

      updateZonesModifiedByOthers(zones) {
         zones.forEach((zone) => {
            this.updateZone(zone.paintedZoneIdScreen, zone.paintedZoneIdFile, zone.soilType);
         })
      }
   },
};
 
</script>
 
<style>

body {
   margin: 0;
   font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
 
#mapid {
   height: 100vh;
   padding: 0;
}
 
.map-overlay {
   font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
   position: absolute;
   width: 300px;
   top: 0;
   right: 0;
   padding: 10px;
}
  
.map-overlay .map-overlay-inner {
   background-color: #fff;
   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
   border-radius: 3px;
   padding: 10px;
   margin-bottom: 10px;
   text-align: center;
}
  
.map-overlay-inner select {
   width: 100%;
}
  
.map-overlay-inner label {
   display: block;
   font-weight: bold;
   margin: 0 0 5px;
}
  
.map-overlay-inner button {
   display: inline-block;
   width: fit-content;
   height: 32px;
   border: none;
   cursor: pointer;
   margin-right: 20px;
}
  
.map-overlay-inner button:focus {
   outline: none;
}
  
.map-overlay-inner button:hover {
   box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1);
}

button {
   font: 14px 'Helvetica Neue', Arial, Helvetica, sans-serif;
   margin: 5px;
}
 
.btn {
   border: none;
   color: white;
   /* padding: 16px 82px; */
   width: 200px;
   text-align: center;
   text-decoration: none;
   /* display: inline-block; */
   font-size: 16px;
   margin-right: 200px;
   margin-bottom: 10px;
   border-radius: 3px;
   cursor: pointer;
   margin-left: auto;
   margin-right: auto;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 20px;
  width: 50%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

input {
   width: 100%;
   margin-bottom: 20px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.scroll {
  background: lightgray;
  height: 150px;
  overflow: scroll;
  border: 1px solid lightgray;
}

</style>./ModalView.vue