<template>
  <ReliefMap/>
</template>

<script>
  import ReliefMap from '@/components/ReliefMap.vue';

  export default {
    name: 'App',
    components: {
      ReliefMap
    },
    data: () => ({
      componentKey: 0
    })
  };
</script>
